import Header from '../Header.vue'
import Footer from '../Footer.vue'
import CheckInService from '../../api/CheckInService.js'
import Page from '../../components/page.vue'
import NoData from '../../components/NoData.vue'
import LoginPop from '../Login/LoginPop.vue'

export default{
	name:'AnLiteratureList',
	data(){
		return{
			pageIndex:1,
			pageSize:15,
			pageCount:0,
			searchWord:'',//搜索内容
			AnLiteratureList:[],//列表
			showLogin:false
		}
	},
	methods:{
		GetAnLiteratureList(){ //获取古文献列表
			var that = this
			CheckInService.GetAnLiteratureList(that.pageIndex,that.pageSize,that.searchWord.trim()).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.AnLiteratureList = res.Result
					that.pageCount = res.TotalCount
				}
			})
		},
		toSearch(){ //搜索
			this.pageIndex = 1
			this.GetAnLiteratureList()
		},
		pageChange(page){ //切换分页
			this.pageIndex = page
			this.GetAnLiteratureList()
			window.scrollTo(0,0)
		},
		toReadingPdf(value){ //古文献pdf阅读
			var that = this
			if(that.$UserID){
				if(that.$ServiceType == 1){//vip
					if(value.PdfReadCount > 0){
						CheckInService.AddAnLiteratureBrowser(value.ID,value.Name,that.$UserID).then(res=>{
							console.log(res)
						})
						window.open(this.$WebUrl+'AnliteratureRead?path='+ value.PdfReadPath + '&pageCount='+ value.PdfReadCount)
					}
				}else{
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定'
					});
				}
			}else{
				that.showLogin = false
				that.showLogin = true
			}
			
			
		},
	},
	created(){
		this.GetAnLiteratureList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'Page':Page,
		'NoData':NoData,
		'LoginPop':LoginPop
	}
}